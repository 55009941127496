import React, { useEffect, useState } from "react";
import "./SessionRatingModal.scss";
import { Rating } from "react-simple-star-rating";
import Modal from "../Modal";

import XMark from "../../assets/xmark.svg";
import StarLogo from "../../assets/star-logo.png";
import Button from "../Button";

const SessionRatingModal = ({
  isOpen,
  setIsOpen,
  bubbles,
  continueEndChat,
  customerName,
  ratingLoader,
}) => {
  const [rating, setRating] = useState(0);
  const [selectedBubbles, setSelectedBubbles] = useState([]);
  const [review, setReview] = useState("");

  const handleBubbleClick = (bubble) => {
    const index = selectedBubbles.findIndex((b) => b === bubble);
    const tempBubbles = [...selectedBubbles];
    if (index > -1) {
      tempBubbles.splice(index, 1);
    } else {
      tempBubbles.push(bubble);
    }
    setSelectedBubbles(tempBubbles);
  };

  useEffect(() => {
    if (isOpen) {
      setRating(0);
      setSelectedBubbles([]);
      setReview("");
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="sessionRatingModal">
        <div className="sessionRatingModal_top">
          <img src={XMark} alt="cross" onClick={() => setIsOpen(false)} />
        </div>
        <img src={StarLogo} alt="star" />
        <h3>How was your session with {customerName}</h3>
        <Rating
          initialValue={rating}
          onClick={(value) => {
            setRating(value);
            setSelectedBubbles([]);
          }}
          emptyColor="#C4C4C4"
          fillColor="#F9B406"
          size={50}
        />
        {bubbles
          .find((b) => b.star === rating)
          ?.data?.map((d, i) => (
            <>
              <h5 key={i}>{d?.title}</h5>
              <div className="sessionRatingModal_bubbles">
                {d?.options?.map((o, j) => (
                  <span
                    key={j}
                    className={
                      selectedBubbles.findIndex((b) => b === o) > -1
                        ? "selected"
                        : ""
                    }
                    onClick={() => handleBubbleClick(o)}
                  >
                    {o}
                  </span>
                ))}
              </div>
            </>
          ))}
        <textarea
          value={review}
          onChange={(e) => setReview(e.target.value)}
          rows={5}
          placeholder="Write a review (optional)"
        />
        <Button
          onClick={() => continueEndChat(review, rating, selectedBubbles)}
          disabled={ratingLoader}
        >
          {ratingLoader ? "Adding..." : "CONTINUE"}
        </Button>
      </div>
    </Modal>
  );
};

export default SessionRatingModal;
