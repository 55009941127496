import React from "react";
import "./EndChatConfirmationModal.scss";
import Modal from "../Modal";
import XMark from "../../assets/xmark.svg";
import CircleXMark from "../../assets/circle-xmark.svg";
import Button from "../Button";

const EndChatConfirmationModal = ({
  isOpen,
  setIsOpen,
  handleYes,
  customerName,
}) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="endChatConfirmationModal">
        <div className="endChatConfirmationModal_top">
          <img src={XMark} alt="cross" onClick={() => setIsOpen(false)} />
        </div>
        <img src={CircleXMark} alt="cross" />
        <h3>Are you sure to end chat with {customerName}</h3>
        <Button outline onClick={() => setIsOpen(false)}>
          No
        </Button>
        <Button onClick={handleYes}>Yes</Button>
      </div>
    </Modal>
  );
};

export default EndChatConfirmationModal;
