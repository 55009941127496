import React, { useEffect, useRef, useState } from "react";
import "./AdvisorDashboard.scss";
import Navbar from "../../components/Navbar";
import AdvisorPostCard from "../../components/AdvisorPostCard";
import { getBubbles, getRelevantPosts, getUser } from "../../apis/apis";
import firebase from "../../Firebase";
import UserIcon from "../../assets/user.png";
import moment from "moment";
import Chat from "../../components/Chat";
import { toast } from "react-toastify";
import Loading from "../../components/Loading";
import { useNavigate } from "react-router-dom";
import EndChatConfirmationModal from "../../components/EndChatConfirmationModal";
import SessionRatingModal from "../../components/SessionRatingModal";
import ThanksModal from "../../components/ThanksModal";

let intervalId = null;

const AdvisorDashboard = () => {
  const sessionsRef = useRef(null);
  const db = firebase.firestore();
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [chats, setChats] = useState(null);
  const [activeChatId, setActiveChatId] = useState("");
  const [chatLoading, setChatLoading] = useState(true);

  // Modals
  const [endChatConfirmationOpen, setEndChatConfirmationOpen] = useState(false);
  const [sessionRatingOpen, setSessionRatingOpen] = useState(false);
  const [thanksModalOpen, setThanksModalOpen] = useState(false);
  const [bubbles, setBubbles] = useState([]);
  const [ratingLoader, setRatingLoader] = useState(false);

  const token = localStorage.getItem("advisorToken");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
    getUserData();
    getPosts();
    localStorage.setItem("activeChatId", "");
    loadBubbles();
  }, []);

  useEffect(() => {
    if (user?.id) {
      getChats();
    }
  }, [user?.id]);

  useEffect(() => {
    intervalId = setInterval(getPosts, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const getUserData = async () => {
    try {
      const res = await getUser(token);
      setUser(res?.data?.user);
    } catch (e) {
      console.log("error", e);
    }
  };

  const loadBubbles = async () => {
    try {
      const res = await getBubbles(token);
      setBubbles(res?.data?.bubbles?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getPosts = async () => {
    try {
      const res = await getRelevantPosts(token);
      if (res?.data?.success) {
        setPosts(res?.data?.posts);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const getChats = async () => {
    db.collection("chats").onSnapshot((snapshot) => {
      var getChats = [];
      snapshot.docs.map((doc) => {
        var obj = doc.data();
        obj.id = doc.id;
        if (obj.advisorId == user?.id && !obj?.endAdvisorChat) {
          getChats.push(obj);
        } else if (
          obj.advisorId == user?.id &&
          obj?.endAdvisorChat === "pending"
        ) {
          getChats.push(obj);
        }
      });
      if (getChats.length !== 0) {
        const preActiveId = localStorage.getItem("activeChatId");

        setActiveChatId(
          preActiveId ? preActiveId : getChats[getChats?.length - 1]?.id
        );

        setChats(getChats);
        setChatLoading(false);
        if (sessionsRef.current && !preActiveId) {
          setTimeout(
            () =>
              (sessionsRef.current.scrollTop =
                sessionsRef.current.scrollHeight),
            100
          );
        }
      } else {
        setChats(null);
        setChatLoading(false);
      }
    });
  };

  const onSend = async (msg, localImage, cb) => {
    if (msg?.length === 0 && !localImage) {
      return;
    }
    localStorage.setItem("activeChatId", activeChatId);
    const temp = {
      createdAt: new Date(),
      text: msg,
      user: {
        _id: user?.id,
        name: user?.firstName + " " + user?.lastName,
        avatar: null,
      },
      image: localImage,
    };
    const createdAt = Date.parse(temp.createdAt);
    const tempChats = [...chats];
    tempChats
      .find((c) => c.id === activeChatId)
      .messages.push({ ...temp, createdAt });
    setChats(tempChats);
    cb();

    await db
      .collection("chats")
      .doc(activeChatId)
      .update(tempChats.find((c) => c.id === activeChatId))
      .then((res) => {
        console.log("active chat id after msg...", activeChatId);
        setActiveChatId(activeChatId);
      })
      .catch((err) => {
        toast.error("Something went wrong.");
      });
  };

  const onEndChatClick = () => {
    setEndChatConfirmationOpen(true);
  };

  const continueEndChat = async (review, star, selectedBubbles) => {
    setRatingLoader(true);
    var reviewObject = {};
    const activeChat = chats?.find((c) => c.id === activeChatId);
    await db
      .collection("Reviews")
      .doc(activeChat.id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          var reviewsData = doc.data();
          reviewObject = {
            advisorComment: review === "" ? " " : review,
            advisorId: activeChat.advisorId,
            advisorStar: star,
            chatID: reviewsData.chatID,
            customerComment: reviewsData.customerComment,
            customerId: reviewsData.customerId,
            customerStar: reviewsData.customerStar,
            postId: reviewsData.postId,
            feedbackForAdvisor: reviewsData.feedbackForAdvisor,
            feedbackForCustomer: selectedBubbles,
            advisorReviewTime: Date.parse(new Date()),
            customerReviewTime: reviewsData.customerReviewTime,
          };
        } else {
          reviewObject = {
            advisorComment: review === "" ? " " : review,
            advisorId: activeChat.advisorId,
            advisorStar: star,
            chatID: activeChat.id,
            postId: activeChat.id,
            feedbackForCustomer: selectedBubbles,
            advisorReviewTime: Date.parse(new Date()),
          };
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
    if (activeChat.endAdvisorChat === "pending") {
      activeChat.endAdvisorChat = true;
      activeChat.endUserChat = true;
    } else {
      activeChat.endAdvisorChat = true;
      activeChat.endUserChat = "pending";
    }
    activeChat.ratingtoCustomer = star;
    activeChat.advisorReview = review;
    activeChat.feedbackForCustomer = selectedBubbles;
    await db.collection("chats").doc(activeChat.id).update(activeChat);
    await db.collection("Reviews").doc(activeChat.id).set(reviewObject);
    setRatingLoader(false);
    setSessionRatingOpen(false);
    setTimeout(() => setThanksModalOpen(true), 100);
  };

  return (
    <>
      <EndChatConfirmationModal
        isOpen={endChatConfirmationOpen}
        setIsOpen={setEndChatConfirmationOpen}
        handleYes={() => {
          setEndChatConfirmationOpen(false);
          setTimeout(() => setSessionRatingOpen(true), 200);
        }}
        customerName={chats?.find((c) => c.id === activeChatId)?.customerName}
      />
      <SessionRatingModal
        bubbles={bubbles}
        isOpen={
          sessionRatingOpen ||
          chats?.find((c) => c.id === activeChatId)?.endAdvisorChat ===
            "pending"
        }
        setIsOpen={setSessionRatingOpen}
        continueEndChat={continueEndChat}
        customerName={chats?.find((c) => c.id === activeChatId)?.customerName}
        ratingLoader={ratingLoader}
      />
      <ThanksModal isOpen={thanksModalOpen} setIsOpen={setThanksModalOpen} />
      <Navbar />
      <div className="advisorDash ">
        <div className="advisorDash_leftBar">
          <div className="advisorDash_posts">
            <h3>Posts</h3>
            <div className="advisorDash_postsContainer">
              {posts?.length > 0 ? (
                posts.map((p, i) => (
                  <AdvisorPostCard
                    key={p.id}
                    data={p}
                    user={user}
                    getPosts={getPosts}
                  />
                ))
              ) : (
                <p>No any posts available.</p>
              )}
            </div>
          </div>
          <div ref={sessionsRef} className="advisorDash_sessions">
            <h3>Open Advice Sessions</h3>
            <div className="">
              {chatLoading && <Loading />}
              {chats?.length > 0 ? (
                chats?.map((chat, i) => {
                  return (
                    <div
                      onClick={() => setActiveChatId(chat?.id)}
                      className={`advisorDash_session ${
                        activeChatId === chat?.id
                          ? "advisorDash_sessionActive"
                          : ""
                      }`}
                    >
                      <div className="advisorDash_sessionSub">
                        <img
                          src={
                            chat?.customerData?.profilePicture
                              ? chat?.customerData?.profilePicture
                              : UserIcon
                          }
                          alt="user"
                        />
                        <div>
                          <h3>{chat?.customerName}</h3>
                          <p>
                            {chat?.messages.length !== 0
                              ? chat?.messages[
                                  chat?.messages?.length - 1
                                ]?.text.substring(0, 10)
                              : ""}
                            {"..."}
                          </p>
                        </div>
                      </div>
                      {chat?.messages?.length > 0 && (
                        <span className="advisorDash_sessionTime">
                          {moment(
                            chat?.messages[chat?.messages?.length - 1]
                              ?.createdAt
                          ).format("hh:mm a")}
                        </span>
                      )}
                    </div>
                  );
                })
              ) : (
                <h4>No chats available</h4>
              )}
            </div>
          </div>
        </div>
        <Chat
          messages={chats?.find((c) => c.id === activeChatId)?.messages}
          userData={user}
          onSend={onSend}
          chatLoading={chatLoading}
          customerName={chats?.find((c) => c.id === activeChatId)?.customerName}
          onEndChatClick={onEndChatClick}
        />
      </div>
    </>
  );
};

export default AdvisorDashboard;
