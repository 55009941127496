import React from "react";
import "./ThanksModal.scss";
import Modal from "../Modal";
import Button from "../Button";
import XMark from "../../assets/xmark.svg";
import FeedbackIcon from "../../assets/feedback-icon.png";

const ThanksModal = ({ isOpen, setIsOpen }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="thanksModal">
        <div className="thanksModal_top">
          <img src={XMark} alt="cross" onClick={() => setIsOpen(false)} />
        </div>
        <img src={FeedbackIcon} alt="feedback" />
        <h3>Thank you for giving feedback.</h3>
        <Button onClick={() => setIsOpen(false)}>Close</Button>
      </div>
    </Modal>
  );
};

export default ThanksModal;
