import axios from "axios";

const URL = "https://us-central1-lifevize-bbfd0.cloudfunctions.net";

export const getPrivacyData = async () => {
  try {
    const res = await axios.get(`${URL}/setting/privacy/R4y2CtWu4Rc9bGIyxSFV`);
    return res?.data;
  } catch (e) {
    console.log(e);
  }
};

export const getTermsData = async () => {
  try {
    const res = await axios.get(`${URL}/setting/terms/4FUGfNegvK6kiY4kl0tV`);
    return res?.data;
  } catch (e) {
    console.log(e);
  }
};

export const getCategoriesData = async () => {
  try {
    const res = await axios.get(`${URL}/category`);
    return res;
  } catch (e) {
    console.log(e);
  }
};

export const createAdvisor = async (data) => {
  try {
    const res = await axios.post(`${URL}/user/advisor/sign-up`, data);
    return res;
  } catch (e) {
    return e;
  }
};

export const loginAdvisor = async (data) => {
  try {
    const res = await axios.post(`${URL}/user/advisor/login`, data);
    return res;
  } catch (e) {
    return e;
  }
};

export const verifyAdvisorOTP = async (data) => {
  try {
    const res = await axios.post(`${URL}/user/advisor/verify-otp`, data);
    return res;
  } catch (e) {
    return e;
  }
};

export const getUser = async (token) => {
  try {
    const res = await axios.get(`${URL}/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (e) {
    throw new Error(e);
  }
};

export const getRelevantPosts = async (token) => {
  try {
    const res = await axios.get(`${URL}/post/relevant`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (e) {
    throw new Error(e);
  }
};

export const acceptPost = async (token, data) => {
  try {
    const res = await axios.post(`${URL}/post/accept`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (e) {
    throw new Error(e);
  }
};

export const makeAdvisorPayment = async (token, data) => {
  try {
    const res = await axios.post(`${URL}/stripe/MakePayment`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (e) {
    throw new Error(e);
  }
};


export const getBubbles = async (token) => {
  try {
    const res = await axios.get(`${URL}/bubble`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    return res;
  } catch (e) {
    throw new Error(e);
  }
};