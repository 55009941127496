import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyCG7nTw3SgQR-VtFoTkJ-_fsB4x62BT0jY",
  authDomain: "lifevize-bbfd0.firebaseapp.com",
  databaseURL: "https://lifevize-bbfd0-default-rtdb.firebaseio.com",
  projectId: "lifevize-bbfd0",
  storageBucket: "lifevize-bbfd0.appspot.com",
  messagingSenderId: "1097672818574",
  appId: "1:1097672818574:web:b1d3098c5a063cbc22eedf",
  measurementId: "G-731Q6DBBR1",
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
  firebase
    .firestore()
    .settings({ experimentalForceLongPolling: true, merge: true });
} else {
  firebase.app(); // if already initialized, use that one
}

export default firebase;
