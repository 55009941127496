import React, { forwardRef, useEffect, useRef, useState } from "react";
import TextInput from "../../components/TextInput";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  createAdvisor,
  getCategoriesData,
  verifyAdvisorOTP,
} from "../../apis/apis";
import { toast } from "react-toastify";
import OTPModal from "../../components/OTPModal";
import Button from "../../components/Button";
import Eye from "../../assets/eye.svg";
import EyeSlash from "../../assets/eye-slash.svg";
import { Link } from "react-router-dom";
import {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const initialValues = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  dob: "",
  about: "",
  forumNames: "",
  privacyCheck: "",
  phoneCheck: "",
};

const ContactTeamSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  about: Yup.string().required("About yourself is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters long.")
    .required("Password is required."),
  dob: Yup.date()
    .max(new Date(), "Selected date cannot be in the future.")
    .required("Birth date is required.")
    .test("minimumAge", "You must be at least 18 years old.", function (value) {
      const currentDate = new Date();
      const selectedDate = new Date(value);
      const minAgeDate = new Date();
      minAgeDate.setFullYear(currentDate.getFullYear() - 18);
      return selectedDate <= minAgeDate;
    }),
  privacyCheck: Yup.bool()
    .required("Need to agree on terms and conditions.")
    .oneOf([true], "You must accept the terms and conditions"),
  phoneCheck: Yup.bool()
    .required("It is required.")
    .oneOf([true], "It is required."),
});

const AdvisorForm = forwardRef((props, ref) => {
  const formikRef = useRef(null);

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState(false);
  const [advisorData, setAdvisorData] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberFocused, setIsPhoneNumberFocused] = useState(false);

  useEffect(() => {
    loadCategories();
  }, []);

  const loadCategories = async () => {
    try {
      const res = await getCategoriesData();
      setCategories(res?.data?.categoriesArray);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleCategoryClick = (id) => {
    if (!selectedCategories.includes(id)) {
      setSelectedCategories((prev) => [...prev, id]);
    } else {
      const temp = [...selectedCategories];
      setSelectedCategories(temp.filter((i) => i !== id));
    }
  };

  const handleSubmit = async (values) => {
    try {
      const { email, firstName, lastName, password, dob, about, forumNames } =
        values;
      const data = {
        categories: selectedCategories,
        firstName,
        lastName,
        email,
        password,
        dob,
        phone: formatPhoneNumberIntl(phoneNumber),
        authenticateLink: forumNames || "http://",
        description: about,
      };
      setFormLoading(true);
      const res = await createAdvisor(data);
      if (!res?.response?.data?.success) {
        toast.error(res?.response?.data?.error);
      }

      if (res?.data?.success) {
        toast.success(res?.data?.message);
        setOtpValue(`${res?.data?.otp}`);
        setAdvisorData(res?.data?.user);
        setOtpModalOpen(true);
      }
      setFormLoading(false);
    } catch (err) {
      console.log(err?.data?.message);
    }
  };

  const verifyOtp = async (value) => {
    if (value === otpValue) {
      const data = {
        otp: value,
        user: advisorData?.id,
      };
      const res = await verifyAdvisorOTP(data);
      if (res?.data?.success) {
        toast.success(res?.data?.message);
        setOtpModalOpen(false);
        formikRef?.current.resetForm();
        setSelectedCategories([]);
        setPhoneNumber("");
        setIsPhoneNumberFocused(false);
      }
      setOtpError(false);
    } else {
      setOtpError(true);
    }
  };

  return (
    <>
      <OTPModal
        isOpen={otpModalOpen}
        setIsOpen={setOtpModalOpen}
        value={otp}
        onChange={(value) => {
          setOtp(value);
          if (value.length === 4) {
            verifyOtp(value);
          } else {
            setOtpError(false);
          }
        }}
        shouldAutoFocus
        otpError={otpError}
      />
      <div ref={ref} className="advisor_form">
        <h3>Sign Up as Advisor</h3>
        <Formik
          initialValues={initialValues}
          validationSchema={ContactTeamSchema}
          onSubmit={(values) => {
            setIsPhoneNumberFocused(true);
            if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) return;

            handleSubmit(values);
          }}
          innerRef={formikRef}
        >
          {(formik) => {
            const { errors, touched } = formik;
            return (
              <Form>
                <div className="advisor_formInputs">
                  <TextInput
                    label="First name"
                    placeholder="Enter First Name"
                    id="firstName"
                    name="firstName"
                    touched={touched}
                    error={errors.firstName}
                  />
                  <TextInput
                    label="Last name"
                    placeholder="Enter Last Name"
                    id="lastName"
                    name="lastName"
                    touched={touched}
                    error={errors.lastName}
                  />
                  <TextInput
                    label="Email"
                    placeholder="Enter Email"
                    id="email"
                    name="email"
                    touched={touched}
                    error={errors.email}
                  />
                  <div className="advisorForm_passwordInput">
                    <TextInput
                      type={passwordShow ? "text" : "password"}
                      label="Password"
                      placeholder="Enter Password"
                      id="password"
                      name="password"
                      touched={touched}
                      error={errors.password}
                    />
                    <img
                      onClick={() => setPasswordShow((prev) => !prev)}
                      src={!passwordShow ? EyeSlash : Eye}
                      alt="eye"
                    />
                  </div>
                  <TextInput
                    type="date"
                    label="Date of Birth"
                    placeholder="Enter Date of Birth"
                    id="dob"
                    name="dob"
                    touched={touched}
                    error={errors.dob}
                    max={new Date().toISOString().substr(0, 10)}
                  />
                  <div className="textInput">
                    <label htmlFor="phone">Phone Number</label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                      international
                      defaultCountry="US"
                      id="phone"
                      onBlur={() => setIsPhoneNumberFocused(true)}
                      required
                    />
                    <span className="error">
                      {phoneNumber
                        ? isValidPhoneNumber(phoneNumber)
                          ? undefined
                          : "Invalid phone number"
                        : isPhoneNumberFocused
                        ? "Phone number required"
                        : ""}
                    </span>
                    <div className="advisor_phoneCheckbox">
                      <div>
                        <Field
                          name="phoneCheck"
                          type="checkbox"
                          id="phoneCheck"
                          className={
                            errors.phoneCheck && touched["phoneCheck"]
                              ? "textInput_error"
                              : null
                          }
                        />
                        <label htmlFor="phoneCheck">
                          You agree to receive text messages from LifeVize. SMS
                          fees may apply.
                        </label>
                      </div>
                      <ErrorMessage
                        name="phoneCheck"
                        component="span"
                        className="error"
                      />
                    </div>
                  </div>

                  <TextInput
                    label="Forum names and usernames"
                    placeholder="Forum names and usernames (optional)"
                    id="forumNames"
                    name="forumNames"
                    touched={touched}
                    error={errors.forumNames}
                  />
                  <TextInput
                    label="About yourself"
                    as="textarea"
                    placeholder="Please tell seekers about yourself..."
                    id="about"
                    name="about"
                    touched={touched}
                    error={errors.about}
                    containerClass="contactTeam_textarea"
                  />
                </div>

                <div className="advisorForm_categoriesContainer">
                  <h4>Select Categories</h4>
                  <div className="advisorForm_categories">
                    {categories?.length > 0 ? (
                      categories?.map((c) => (
                        <>
                          <div
                            key={c?.id}
                            onClick={() => handleCategoryClick(c?.id)}
                            className="advisorForm_parentCategory"
                          >
                            {/* <input
                              type="checkbox"
                              readOnly
                              checked={selectedCategories.indexOf(c?.id) > -1}
                            /> */}
                            <img src={c?.image} height="30" alt={c?.name} />
                            <span>{c?.name}</span>
                          </div>
                          {c?.subCategories?.map((s) => (
                            <div
                              key={s?.id}
                              onClick={() => handleCategoryClick(s?.id)}
                              className="advisorForm_childCategory"
                            >
                              <input
                                type="checkbox"
                                readOnly
                                checked={selectedCategories.indexOf(s?.id) > -1}
                              />
                              <img src={s?.image} height="30" alt={s?.name} />
                              <span>{s?.name}</span>
                            </div>
                          ))}
                        </>
                      ))
                    ) : (
                      <span>Loading...</span>
                    )}
                  </div>
                </div>
                <div className="advisor_privacyCheckbox">
                  <div>
                    <Field
                      name="privacyCheck"
                      type="checkbox"
                      id="privacyCheck"
                      className={
                        errors.privacyCheck && touched["privacyCheck"]
                          ? "textInput_error"
                          : null
                      }
                    />
                    <label htmlFor="privacyCheck">
                      By continuing you agree to our{" "}
                      <Link to="/terms">Terms of Services </Link> &{" "}
                      <Link to="/privacy"> Privacy Policy</Link>.
                    </label>
                  </div>
                  <ErrorMessage
                    name="privacyCheck"
                    component="span"
                    className="error"
                  />
                </div>
                <Button disabled={formLoading} type="submit">
                  {formLoading ? "Signing Up..." : "Sign Up"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
});

export default AdvisorForm;
