import React, { useState } from "react";
import "./OTPModal.scss";
import OtpInput from "react-otp-input";
import Modal from "../Modal/Modal";
import Button from "../Button";

const OTPModal = ({ isOpen, setIsOpen, otpError, ...props }) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={`otpModal ${otpError ? "otpModal_error" : ""}`}>
        <h3>Enter Your OTP</h3>
        <OtpInput
          numInputs={4}
          {...props}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
        />
        {otpError && <span className="error">Invalid OTP.</span>}
      </div>
    </Modal>
  );
};

export default OTPModal;
