import React, { useState } from "react";
import "./Navbar.scss";
import LogoIcon from "../../assets/logo.png";
import { useNavigate } from "react-router-dom";
import AdvisorLoginModal from "../AdvisorLoginModal";

const NavItem = ({ title, options, path }) => {
  const location = window.location.pathname;
  const navigate = useNavigate();

  return (
    <div className={`navItem ${location === path ? "navItem_active" : ""}`}>
      <span onClick={() => navigate(path)}>{title}</span>
      {options?.length > 0 && (
        <>
          <div className="navItem_menu">
            {options.map((opt, i) => (
              <div
                className={`${location === opt.path ? "navItem_active" : ""}`}
                onClick={() => navigate(opt.path)}
                key={i}
              >
                {opt.option}{" "}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

const HamburgerIcon = ({ active, handleMenuClick }) => {
  return (
    <div
      onClick={handleMenuClick}
      className={`hamburger ${active ? "is-active" : ""}`}
    >
      <span className="line"></span>
      <span className="line"></span>
      <span className="line"></span>
    </div>
  );
};

const NavMain = ({ setAdvisorLoginModal }) => {
  const navigate = useNavigate();
  const advisorToken = localStorage.getItem("advisorToken");

  const advisorLogout = () => {
    localStorage.setItem("advisorToken", "");
    navigate("/");
  };

  return (
    <>
      <div className="navbar_items">
        {advisorToken && (
          <NavItem title="Dashboard" path="/advisor-dashboard" />
        )}
        <NavItem title="Home" path="/" />
        <NavItem title="Advise" path="/advise" />
        <div className={`navItem `}>
          <a href="mailto:admin@lifevize.com">Invest</a>
        </div>
        <div className={`navItem `}>
          <span
            onClick={() =>
              advisorToken ? advisorLogout() : setAdvisorLoginModal(true)
            }
          >
            Advisor {advisorToken ? "Logout" : "Login"}
          </span>
        </div>
      </div>
    </>
  );
};

const Navbar = () => {
  const [menuActive, setMenuActive] = useState(false);
  const navigate = useNavigate();
  const [advisorLoginModal, setAdvisorLoginModal] = useState(false);
  return (
    <>
      <AdvisorLoginModal
        isOpen={advisorLoginModal}
        setIsOpen={setAdvisorLoginModal}
      />
      <nav className="navbar">
        <div className="container">
          <img
            onClick={() => navigate("/")}
            className="navbar_logo"
            src={LogoIcon}
            alt="logo"
          />
          <NavMain setAdvisorLoginModal={setAdvisorLoginModal} />
          <HamburgerIcon
            active={menuActive}
            handleMenuClick={() => setMenuActive(!menuActive)}
          />
        </div>
        {menuActive && (
          <div className="navbar_mobileMenu">
            <NavMain setAdvisorLoginModal={setAdvisorLoginModal} />
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
