import React from "react";
import "./Button.scss";

const Button = ({ parentClass = "", outline = false, children, ...rest }) => {
  return (
    <button
      className={`Button  ${outline ? "Button_outline" : ""} ${parentClass}`}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
