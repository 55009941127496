import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Home from "../pages/Home";
import Advise from "../pages/Advise";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Terms from "../pages/Terms";
import AdvisorDashboard from "../pages/AdvisorDashboard";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/advise" exact element={<Advise />} />
        <Route path="/advisor-dashboard" exact element={<AdvisorDashboard />} />
        <Route path="/privacy" exact element={<PrivacyPolicy />} />
        <Route path="/terms" exact element={<Terms />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
