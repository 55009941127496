import React, { useRef, useState } from "react";
import "./AdvisorLoginModal.scss";
import Modal from "../Modal";
import TextInput from "../TextInput";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { createAdvisor, loginAdvisor } from "../../apis/apis";
import Eye from "../../assets/eye.svg";
import EyeSlash from "../../assets/eye-slash.svg";
import Button from "../Button";
import XMark from "../../assets/xmark.svg";
import { useNavigate } from "react-router-dom";

const initialValues = {
  email: "",
  password: "",
};

const ContactTeamSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required."),
});

const AdvisorLoginModal = ({ isOpen, setIsOpen }) => {
  const formikRef = useRef(null);
  const navigate = useNavigate();

  const [formLoading, setFormLoading] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const { email, password } = values;
      const data = {
        email,
        password,
      };
      setFormLoading(true);
      const res = await loginAdvisor(data);
      if (!res?.response?.data?.success) {
        toast.error(res?.response?.data?.error);
      }

      if (res?.data?.success) {
        localStorage.setItem("advisorToken", res?.data?.token);
        navigate("/advisor-dashboard");
        toast.success(res?.data?.message);
      }
      setFormLoading(false);
    } catch (err) {
      console.log(err?.data?.message);
    }
  };
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="advisorLoginModal">
        <div className="advisorLoginModal_xmark">
          <img onClick={() => setIsOpen(false)} src={XMark} alt="cross" />
        </div>
        <h3>Advisor Login</h3>

        <Formik
          initialValues={initialValues}
          validationSchema={ContactTeamSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          innerRef={formikRef}
        >
          {(formik) => {
            const { errors, touched } = formik;
            return (
              <Form>
                <TextInput
                  label="Email"
                  placeholder="Enter Email"
                  id="email"
                  name="email"
                  touched={touched}
                  error={errors.email}
                />
                <div className="advisorForm_passwordInput">
                  <TextInput
                    type={passwordShow ? "text" : "password"}
                    label="Password"
                    placeholder="Enter Password"
                    id="password"
                    name="password"
                    touched={touched}
                    error={errors.password}
                  />
                  <img
                    onClick={() => setPasswordShow((prev) => !prev)}
                    src={!passwordShow ? EyeSlash : Eye}
                    alt="eye"
                  />
                </div>

                <Button disabled={formLoading} type="submit">
                  {formLoading ? "Signing in..." : "Sign In"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default AdvisorLoginModal;
