import React, { useEffect, useState } from "react";
import "./AdvisorPostCard.scss";
import UserIcon from "../../assets/user.png";
import DownArrows from "../../assets/down-arrow-logo.png";
import UpArrows from "../../assets/up-arrow-logo.png";
import Button from "../Button";
import moment from "moment";
import firebase from "../../Firebase/index";
import { acceptPost, makeAdvisorPayment } from "../../apis/apis";

const AdvisorPostCard = ({ data, user, getPosts }) => {
  const db = firebase.firestore();

  const [showDetails, setShowDetails] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem("advisorToken");

  useEffect(() => {
    (async () => {
      await db
        .collection("User")
        .doc(data.customerId)
        .get()
        .then((doc) => {
          var customer = doc.data();
          customer.id = doc.id;
          setCustomerData(customer);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, []);

  const acceptSession = async () => {
    var obj = data;

    obj.messages = [
      {
        createdAt: Date.parse(new Date()),
        text: obj.question,
        user: {
          _id: customerData.id,
          avatar: null,
          name: customerData.firstName + " " + customerData.lastName,
        },
      },
    ];
    obj.advisorId = user?.id;
    obj.name = user?.firstName + " " + user?.lastName;
    obj.endUserChat = false;
    obj.endAdvisorChat = false;

    // console.log(obj);
    setLoading(true);

    obj.customerName = customerData.firstName + " " + customerData.lastName;
    try {
      const res = await acceptPost(token, { id: data?.id });
      if (res?.data?.success) {
        delete obj.loading;
        await db
          .collection("chats")
          .doc(obj.id)
          .set(obj)
          .then(() => {
            makePayment(obj.advisorId, obj.bid, obj.id);
            getPosts();
            // Toast.show('post accepted sucesfully');
            // navigation.navigate('AdvisorChatScreen', {
            //   chatId: obj.id,
            //   BID: obj.bid,
            //   STRIPE_ID:json.stripe_account_id
            // });
          })
          .catch((error) => {
            setLoading(false);
            console.error("Error adding document: ", error);
          });
      } else {
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log("error", e);
    }
  };

  const makePayment = async (AdvID, Bid, ID) => {
    var BID = Bid;
    var am = parseInt(BID);
    var data = {
      amount: am,
      currency: "USD",
      advisiorAmount: am,
      advisiorId: AdvID,
      bid_id: ID,
      customerId: customerData.id,
    };
    setLoading(true);
    try {
      const res = await makeAdvisorPayment(token, data);
      setLoading(false);
    } catch (err) {
      console.log("error", err);
      setLoading(false);
    }
  };

  return (
    <div className="advisorPostCard">
      <div className="advisorPostCard_header">
        <img src={data?.customerData?.profilePicture || UserIcon} alt="user" />
        <div>
          <h3>
            {data?.customerData?.firstName} {data?.customerData?.lastName}
          </h3>
          <span>
            {data?.categoryData?.name}, {data?.subCategoryData?.name}
          </span>
        </div>
      </div>
      <div className="advisorPostCard_time">
        {" "}
        {moment.unix(data?.timeStamp).format("DD MMM YYYY, HH:mm a")}
      </div>
      <div className="advisorPostCard_query">
        {showDetails && (
          <span className="advisorPostCard_queryMutedText">Your inquiry</span>
        )}
        <p className="advisorPostCard_inquiry">
          {data?.question.slice(0, 100)}
          {"... "}
          {!showDetails && (
            <span
              onClick={() => setShowDetails(!showDetails)}
              className="advisorPostCard_expander"
            >
              View More <img src={DownArrows} alt="more" />{" "}
            </span>
          )}
        </p>

        {showDetails && (
          <div className="advisorPostCard_queryDetails">
            <h3>
              {data?.postType === "auto" ? "Vehicle Details" : "House Details"}{" "}
            </h3>
            <div className="advisorPostCard_queryDetail">
              <p>{data?.postType === "auto" ? "Make" : "Housing Type"}</p>
              <h4>
                {data?.postType === "auto" ? data?.make : data?.housingType}
              </h4>
            </div>
            <div className="advisorPostCard_queryDetail">
              <p>{data?.postType === "auto" ? "Model" : "Type"}</p>
              <h4>{data?.postType === "auto" ? data?.model : data?.type}</h4>
            </div>
            <div className="advisorPostCard_queryDetail">
              <p>{data?.postType === "auto" ? "Year" : "Problem"}</p>
              <h4>{data?.postType === "auto" ? data?.year : data?.problem}</h4>
            </div>
          </div>
        )}

        {showDetails && (
          <span
            onClick={() => setShowDetails(!showDetails)}
            className="advisorPostCard_expander"
          >
            View Less <img src={UpArrows} alt="more" />{" "}
          </span>
        )}
      </div>
      <div className="advisorPostCard_offer">
        Offer:
        <p>${data?.bid}</p>
      </div>
      <Button onClick={acceptSession}>
        {loading ? "ACCEPTING SESSION..." : "ACCEPT SESSION"}
      </Button>
    </div>
  );
};

export default AdvisorPostCard;
