import React, { useEffect, useRef, useState } from "react";
import "./Chat.scss";
import { getUser } from "../../apis/apis";
import firebase from "../../Firebase";
import Loading from "../Loading";

const Chat = ({
  messages,
  userData,
  onSend,
  chatLoading,
  customerName,
  onEndChatClick,
}) => {
  const chatRef = useRef(null);

  const [imageLoader, setImageLoader] = useState(false);
  const [localImage, setLocalImage] = useState(null);
  const [msg, setMsg] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (chatRef.current) {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
      }
    }, 200);
  }, [messages]);

  //     db.collection("chats")
  //       .doc(chatId)
  //       .onSnapshot((doc) => {
  //         // setLoading(false);
  //         var chatData = doc.data();
  //         if (chatData?.endAdvisorChat !== true) {
  //           if (chatData?.endAdvisorChat === "pending") {
  //             // setRatingModalVisible(!ratingModalVisible);
  //           }
  //           setChats(doc.data());
  //           setMessages(chatData?.messages);
  //         } else {
  //           setChats(null);
  //           setMessages([]);
  //         }
  //       });
  //   }
  // };

  const onImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      setImageLoader(true);
      const storageRef = firebase.storage().ref();

      const imageName = `${Date.now()}_${selectedFile?.name}`;

      const imageRef = storageRef.child(`images/${imageName}`);
      imageRef
        .put(selectedFile)
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((downloadURL) => {
          setLocalImage(downloadURL);
          setImageLoader(false);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          setImageLoader(false);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setMsg(msg + "\n");
    } else if (e.key === "Enter") {
      e.preventDefault();
      onSend(msg, localImage, sendMsgCB);
      setMsg("");
      setLocalImage(null);
    }
  };

  const sendMsgCB = () => {
    setLocalImage(null);
    setMsg("");
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  };

  return (
    <section className="msger">
      <header className="msger-header">
        <div className="msger-header-title">{customerName}</div>
        {customerName && (
          <div className="msger-header-end" onClick={onEndChatClick}>
            End Chat
          </div>
        )}
      </header>

      <main className="msger-chat" ref={chatRef}>
        {chatLoading && <Loading />}
        {messages?.map((msg) => (
          <div
            className={`msg ${
              msg?.user?._id == userData?.id ? "right-msg" : "left-msg"
            } ${msg?.messageType === "tip" ? "msg-tip" : ""} `}
          >
            {msg?.messageType === "tip" && (
              <div>You got a tip of {msg?.totalTip}$.</div>
            )}

            {(msg?.text || msg?.image) && (
              <div className="msg-bubble">
                {msg?.image && <img src={msg?.image} alt="" />}
                <div className="msg-text">
                  {msg?.text?.split("\n").map((line, i) => (
                    <React.Fragment key={i}>
                      {line}{" "}
                      {i !== msg?.text?.split("\n")?.length - 1 && <br />}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </main>

      <div className="msger-inputarea">
        <textarea
          className="msger-input"
          placeholder="Enter your message..."
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
          onKeyDown={handleKeyDown}
          rows={1}
          disabled={!customerName}
        ></textarea>
        <label htmlFor="imageUploader">
          Attach
          <input
            type="file"
            id="imageUploader"
            accept=".svg, .png, .jpeg, .jpg"
            style={{ display: "none" }}
            onChange={onImageChange}
            disabled={!customerName}
          />
        </label>

        <button
          onClick={() => onSend(msg, localImage, sendMsgCB)}
          disabled={imageLoader || !customerName}
          type="submit"
          className="msger-send-btn"
        >
          Send
        </button>
      </div>
      {(imageLoader || localImage) && (
        <div className="chat_imageContainer">
          {imageLoader && <Loading />}
          {localImage && <img src={localImage} alt="localImage" />}
        </div>
      )}
    </section>
  );
};

export default Chat;
