import React from "react";
import "./Home.scss";
import HomeImg1 from "../../assets/home-img1.png";
import HomeImg2 from "../../assets/home-img2.png";
import HomeImg3 from "../../assets/home-img3.png";
import AppleLogo from "../../assets/apple-app-store.svg";
import GoogleLogo from "../../assets/google-play-badge.svg";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <Layout>
      <div className="home">
        <div className="home_container">
          <h1>Fact Check Your Repair Technician.</h1>
          <h4>Get Live Help Now.</h4>
          <div className="home_images">
            <img src={HomeImg1} alt="maintenance" />
            <img src={HomeImg2} alt="chat" />
            <img src={HomeImg3} alt="Auto" />
          </div>
          <div className="home_logos">
            <div>
              <img src={AppleLogo} alt="apple" />
              <p>coming soon</p>
            </div>
            <Link
              to="https://play.google.com/store/apps/details?id=com.lifevizeapp"
              target="_blank"
            >
              <img src={GoogleLogo} alt="google" />
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
