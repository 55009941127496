import React, { useRef } from "react";
import "./Advise.scss";
import Navbar from "../../components/Navbar";
import AdviseImg1 from "../../assets/advise-img1.png";
import AdviseImg2 from "../../assets/advise-img2.png";
import AdviseImg3 from "../../assets/advise-img3.png";
import AngleDownIcon from "../../assets/angle-down-solid.svg";
import AppleLogo from "../../assets/apple-app-store.svg";
import GoogleLogo from "../../assets/google-play-badge.svg";
import Logo from "../../assets/logo.png";
import Layout from "../../components/Layout";
import AdvisorForm from "./AdvisorForm";
import { Link } from "react-router-dom";

const OPTIONS1 = [
  "Engine issues",
  "Overheating",
  "Transmission",
  "Tires/wheels",
  "brakes",
  "steering",
  "inspections",
];
const OPTIONS2 = [
  "Plumbing",
  "Heating/AC",
  "floors",
  "drywall/paint",
  "doors/windows",
  "roofing/leaks",
  "appliances",
];

const Advise = () => {
  const downloadRef = useRef();

  return (
    <Layout>
      <div className="advise">
        <div className="advise_container">
          <h1>give advice and get paid.</h1>
          <h2>
            use your knowledge and problem solving abilities to to earn up to
            $100/HR or more
          </h2>
          <div className="advise_images">
            <img src={AdviseImg1} alt="chat1" />
            <img src={AdviseImg2} alt="chat2" />
            <img src={AdviseImg3} alt="chat3" />
          </div>

          <img
            onClick={() =>
              downloadRef.current.scrollIntoView({ behavior: "smooth" })
            }
            className="advise_downIcon"
            src={AngleDownIcon}
            alt="down"
          />

          <h4>customers bid for help</h4>
          <p>
            customers bid $10 - $100 for help. Customers may tip additional
            amounts during session.
          </p>
          <p>
            accept inquires you feel you can assist in from the comfort of your
            home
          </p>

          <h4>advisors are carefully selected</h4>
          <p>
            Platform managers strive to ensure the right number of advisors to
            customers
          </p>
          <p>a steady flow of work to advisors</p>

          <h4>low platform fees</h4>
          <p>
            advisors keep 80% of initial question bid amount and 100% of tips,
            minus credit card processing fees.
          </p>
          <p>no cap on sessions or hours</p>

          <AdvisorForm ref={downloadRef} />

          <h4>Download the app to apply</h4>
          <div className="advise_logos">
            <img src={AppleLogo} alt="apple" />
            <Link
              to="https://play.google.com/store/apps/details?id=com.lifevizeapp"
              target="_blank"
            >
              <img src={GoogleLogo} alt="google" />
            </Link>
          </div>

          <h3>Specialize in any of the below Areas of Auto & home advice:</h3>

          <div className="advise_optionsContainer">
            <div className="advise_options">
              {OPTIONS1.map((opt, i) => (
                <span key={i}>{opt}</span>
              ))}
            </div>
            <div className="advise_options">
              {OPTIONS2.map((opt, i) => (
                <span key={i}>{opt}</span>
              ))}
            </div>
          </div>

          <h3>Download the app to apply:</h3>

          <div className="advise_logos">
            <img src={AppleLogo} alt="apple" />
            <Link
              to="https://play.google.com/store/apps/details?id=com.lifevizeapp"
              target="_blank"
            >
              <img src={GoogleLogo} alt="google" />
            </Link>
          </div>
        </div>
        <div className="advise_footer">
          <img src={Logo} alt="logo" />
        </div>
      </div>
    </Layout>
  );
};

export default Advise;
