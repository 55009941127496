import React from "react";
import "./Footer.scss";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <a className="footer_link" href="mailto:admin@lifevize.com">
        admin@lifevize.com
      </a>
      <Link to="/privacy">Privacy Policy</Link>
      <Link to="/terms">Terms of Services</Link>
    </footer>
  );
};

export default Footer;
