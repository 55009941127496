import React, { useEffect, useState } from "react";
import "./PrivacyPolicy.scss";
import Layout from "../../components/Layout";
import { getPrivacyData } from "../../apis/apis";
import Loading from "../../components/Loading";

const PrivacyPolicy = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    const res = await getPrivacyData();
    setIsLoading(false);
    if (res?.success) {
      setHtmlContent(res?.privacy?.html);
    }
  };

  if (isLoading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }
  return (
    <Layout>
      <div
        className="privacyPolicy"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </Layout>
  );
};

export default PrivacyPolicy;
