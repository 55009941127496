import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import "./Terms.scss";
import { getTermsData } from "../../apis/apis";
import Loading from "../../components/Loading";

const Terms = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setIsLoading(true);
    const res = await getTermsData();
    setIsLoading(false);
    if (res?.success) {
      setHtmlContent(res?.terms?.html);
    }
  };

  if (isLoading) {
    return (
      <Layout>
        <Loading />
      </Layout>
    );
  }

  return (
    <Layout>
      <div
        className="terms"
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      ></div>
    </Layout>
  );
};

export default Terms;
